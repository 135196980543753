import React from 'react'
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby"

import { H2Section } from '../styles/shared.styles'

interface Props {
  hideSeeMore?: boolean
  embedded?: boolean
}

export default function WorkSection({ hideSeeMore = false, embedded = false}: Props) {
  return (
    <WorkSectionWrapper id="work-section">
      <H2Title>What we do</H2Title>
      <CardsWrapper>
        <Card>
          <IconWrapper>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon team-extension" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
            </svg>
          </IconWrapper>
          <h3>Team extension</h3>
          <p>A fully dedicated remote team or person. For companies who want to have just one contact point for the duration of the project.</p>
        </Card>
        <Card>
          <IconWrapper>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon project" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
            </svg>
          </IconWrapper>
          <h3>Project</h3>
          <p>Classic model that guarantees the delivery of a predefined set of functionalities within a specific time frame.</p>
        </Card>
        <Card>
          <IconWrapper>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon ecommerce" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
          </IconWrapper>
          <h3>E-Commerce</h3>
          <p>We build your online shop very fast.</p>
        </Card>
        <Card>
          <IconWrapper>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon website" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
            </svg>
          </IconWrapper>
          <h3>Static website</h3>
          <p>We build static websites very fast and cheap. Let's build your landing page.</p>
        </Card>
        <Card>
          <IconWrapper>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon chart" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
            </svg>
          </IconWrapper>
          <h3>Analytics</h3>
          <p>We provide you tools to see metrics and helpful insights of your app or service.</p>
        </Card>
        <CallToAction>
          <h2>Have an idea in mind?</h2>
          {!embedded ? <AnchorLink to="#contact-section"><p>Let's get in touch!</p></AnchorLink> : <Link to="/#contact-section"><p>Let's get in touch!</p></Link>}
        </CallToAction>
      </CardsWrapper>
      <ColorSection />
      {!hideSeeMore && <Link to="/what-we-do"><SeeMoreButton type='button'>See more</SeeMoreButton></Link>}
    </WorkSectionWrapper>
  )
}

const WorkSectionWrapper = styled.section`
  display: flex;
  position: relative;
  padding: 100px 30px;
  padding-bottom: 0;
  background-color: #B83B5E;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  a:hover, a:focus, a: visited {
    text-decoration: none;
  }

  @media screen and (min-width: 1280px) {
    padding: 200px 50px;
  }
`
const ColorSection = styled.div`
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 10px;
  background: #B83B5E;
`
const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 50px;
  justify-content: space-between;
  z-index: 1;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
  }
`
const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 350px;
  width: 100%;
  border: 3px solid #F9ED69;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  padding: 40px;
  text-align: center;

  h3 {
    font-size: 28px;
    font-weight: 700;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  p {
    color: #fff;
    font-size: 18px;
    width: 100%;
  }

  @media screen and (min-width: 1280px) {
    width: 350px;
    margin-right: 40px;
    text-align: left;
  }
`
const IconWrapper = styled.div`
  width: 50px;
  height: 50px;

  svg {
    stroke: #F9ED69;
  }
`
const H2Title = styled(H2Section)`
  color: #fff;
`
const CallToAction = styled(Card)`
  border-color: #F08A5D;
  text-align: left;

  h2 {
    color: #fff;
    font-size: 2.5em;
    font-weight: 700;
  }

  p {
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
  }

  p:hover {
    color: #F9ED69;
  }

  a {
    width: 100%;
    text-decoration: none;
  }

  @media screen and (min-width: 1280px) {
    h2 {
      font-size: 57px;
    }
  }
`

const SeeMoreWrapper = styled.div`
  display: flex;
  padding: 20px 0;
  color: #F9ED69;

  a {
    font-size: 2rem;
    text-decoration: none;
    width: 100%;
    color: #F9ED69;
  }
`

const SeeMoreButton = styled.button`
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #F9ED69;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #6A2C70;
  font-weight: 700;
  border: 1px solid #333;
  margin-top: 40px;
  margin-bottom: 40px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: #F9ED69;
    color: #6A2C70;
    text-decoration: none;
  }

  @media screen and (min-width: 1280px) {
    width: 200px;
  }
`