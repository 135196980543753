import React from 'react'
import styled from "styled-components"
import { Link } from "gatsby"

// Styles
import '../styles/reset.css'
import { MainComponent } from '../styles/shared.styles'

import SimpleHeader from '../components/SimpleHeader'
import WorkSection from '../components/WorkSection'
import Footer from '../components/Footer'

export default function WhatWeDo() {
  return (
    <MainComponent>
      <SimpleHeader transparent color="#B83B5E" />
      <WorkSection hideSeeMore={true} embedded />
      
      <MoreInfo>
        <h2>We can work on that idea you have in mind</h2>
        <p>After we meet and discuss dealines, deals, versions and tech stack, we start working on your project.</p>
        <p>We can adapt depending on your idea. For instance, if you need one more developer for your team, we can get you to meet one of our talented developers. However, if that is not the case, we work on your project while you can be involved in the process, see demos and documentation, and even test the website or application.</p>
        <p>We use the latest technologies, and we know how to use them well. We can explain whatever you need, such as a piece of code, tool, documentation or any doubt you can have in the process.</p>
        <p>We also use different and well-known communication and project management tools. So we can use those you are familiar with.</p>
        <p>You can see <Link to="/who-we-are">here</Link> the technologies, services and tools we use.</p>
      </MoreInfo>
      
      <Footer />
    </MainComponent>
  )
}

const MoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 23px;
  padding: 200px 50px;

  h2 {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 40px;
  }
  
  p {
    margin-bottom: 20px;
  }
`